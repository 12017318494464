import React from 'react';
import { paths } from '@src/constants/pathsEnum';

import featuredImage from './images/featured-image.jpg';

import * as styles from './payload.module.scss';

import {
  go,
  python,
  pytest,
  ansible,
  terraform,
  puppet,
  saltStack,
  travisCI,
  bamboo,
  jenkins,
  spinnaker,
  huawei,
  zuul,
  vault,
  vagrant,
  helm,
  juju,
  groovy,
  git,
  fluentd,
  prometheus,
  grafana,
  elkStack,
  aws,
  redHat,
  openStack,
  kubernetes,
  docker,
  envoy,
  istio,
  kvm,
  xen,
  etcd,
  mariaDB,
  postgreSQL,
  p4,
  sonic,
  tungstenFabric,
  f5,
  cisco,
  aristaNetworks,
  nokiaNetworks,
  checkPoint,
  juniperNetworks,
  paloAltoNetworks,
  openVSwitch,
  mongoDB,
  openShift,
  ibmCloud,
  azure,
  googleCloud,
  gitLab,
  circleCI,
  cPlusPlus,
  dell,
} from '@images/logos-techstack';

export const networkServicesSEOProps = {
  title: 'Network Professional Services Company - CodiLime',
  description:
    'We provide network professional services, including network automation, cloud solutions, integration, and validation. See how we help.',
  featuredImage: `https://codilime.com${featuredImage}`,
};

export const headerProps = {
  title: (
    <>
      Network <br />
      Professional <br />
      Services
    </>
  ),
  isBorder: false,
};

export const sectionUnderHeaderProps = {
  //contactButtonLabel: 'Improve your network',
  contactButtonLabel: 'Book a call', // CRO test
  textPartOne: (
    <>
      Network infrastructures grow more and more complex every year. With increased cloud use, virtualization, and
      automation becoming the standard, every organization needs a way of improving and developing
    </>
  ),
  textPartTwo: (
    <>
      {' '}
      their solutions. CodiLime gathers experienced network engineers, SDN supporters, cloud enthusiasts and automation
      experts. We can guide you towards a modern, automated network - whether you’re already cloud-based or still a
      traditional network.
    </>
  ),
};

export const blocksInRowsWithOneActiveProps = {
  title: 'Our areas of expertise',
  subtitle: (
    <>
      At CodiLime, we&apos;ve been gaining experience in various fields of network services since 2011. Whether
      it&apos;s <b>telco, DC, security, or enterprise</b> - we do it all. From design to deployment, with supporting,
      and training your team all the way, you can count on us to deliver{' '}
      <b>a network infrastructure up to the latest industry standards.</b>
    </>
  ),
  blocks: [
    {
      title: 'Networks & Clouds',
      description: (
        <>
          We have expertise in building and integrating cloud solutions based on private, public, and hybrid
          environments for enterprises, telcos, and data centers. Our knowledge of <b>Linux-based systems and SDNs</b>{' '}
          ensures scalable solutions in line with client requirements. We also provide support in daily operations.
        </>
      ),
    },
    {
      title: 'Network automation',
      description: (
        <>
          Automation has become a standard practice in the network industry. It helps to{' '}
          <b>accelerate deployment and day to day operations</b> and <b>minimizes the risk of human error.</b> We
          provide automation for newly-deployed solutions and enhance existing ones. We also guide teams through
          migration from traditional management to automated ones.
        </>
      ),
    },
    {
      title: 'Integration & validation',
      description: (
        <>
          Validation is what ensures that you are left with a <b>solution in line with your requirements</b>. We perform
          various tests, such as <b>performance, scalability, functionality, stress, and interoperability.</b> Our teams
          analyze test results, specify pain points and find ways of improving given solutions. We also provide support
          throughout the integration process.
        </>
      ),
    },
  ],
  classNames: {
    section: styles.blocksInRowsWithOneActiveSection,
    innerWrapper: styles.blocksInRowsWithOneActiveInnerWrapper,
    subtitleStyles: styles.customSubtitle,
    singleBlockContainer: styles.singleBlockContainer,
    titlesContainer: styles.titlesContainer,
  },
};

export const contactButtonImageSectionProps = {
  sectionProps: {
    classNames: {
      innerWrapper: styles.contactButtonImageSectionInnerWrapper,
      section: styles.contactButtonImageSection,
    },
  },
  title: <>Reach your business goals with network professional services</>,
  buttonTitle: 'Contact us',
  imageAlt: 'Inquire about professional network services',
  isH2: true,
  classNames: { customContent: styles.content, imageContainer: styles.contactButtonImageSectionImageContainer },
};

export const flipCardsSectionProps = {
  sectionProps: {
    title: 'Our expertise in practice',
    id: 'use-cases',
    subtitle: (
      <>
        Network professional services provide necessary solutions for growth and development of any technology company.
        We create <b>custom solutions tailored to the needs of our partners</b> - you give us a challenge, and we
        provide the solution.
        <br className={styles.paragraphWithGap} />
        Take a look at what we&apos;ve already done for our clients.
      </>
    ),
    classNames: {
      innerWrapper: styles.documentAndExpertiseSectionInnerWrapper,
    },
  },
  customCardsContainerStyles: styles.cardContainer,

  isFlippable: true,
  flipCardsData: [
    {
      title: 'Preparing a safe and automated upgrade of an NFV telco cloud',
      description: (
        <>
          We used a dedicated pre-production environment and covered the entire testing process. The comparative
          analysis of the solution before and after the upgrade proved an unimpaired performance and functionalities
          after an upgrade of an SDN deployment. The tech stack included OpenStack, Tungsten Fabric, Maas and Juju.
        </>
      ),
      color: '#041C2E',
      paddingTop: '78px',
    },
    {
      title: 'VNF deployment in public clouds',
      description: (
        <>
          We built a solution as a Terraform code that ensures an easy, quick, fully automated and repeatable process of
          deployment for VNF and related public cloud resources. We ensured knowledge transfer to the client&apos;s team
          and provided ongoing support. The tech stack included GCP, AWS, Microsoft Azure, Terraform, Go, Python.
        </>
      ),
      color: '#053356',
      paddingTop: '100px',
    },
    {
      title: 'Configuration unification for network devices',
      description: (
        <>
          We built a highly flexible software for automation of network device configuration unification with the goal
          to ensure functional cohesion. Our solution enabled fast, safe, and reliable operations with minimal operator
          intervention. The tech stack included Python, Pytest, and PostgreSQL.
        </>
      ),
      color: '#122B3F',
      paddingTop: '76px',
    },
    {
      title: 'Cost-effective virtualized network solution for a private cloud',
      description: (
        <>
          We delivered <b>hardware and license expenditure optimization</b> through the use and auto-scaling of
          virtualized network appliances. The selected architecture provided the largest performance gains while keeping
          the solution open to address future requirements and utilizing off-the-shelf server-grade components. The tech
          stack included Open vStack, KVM, and VMware ESXi.
        </>
      ),
      color: '#021A2D',
      paddingTop: '76px',
    },
    {
      title: 'IP fabric configuration for a data center',
      description: (
        <>
          We <b>automated data center fabric configuration</b> and runtime management. The solution was highly scalable,
          easy to test and maintain. It minimized the number of manually-set parameters and the operations needed for
          configuration and runtime modifications of the fabric network. The tech stack included Go, PostgreSQL,
          Kubernetes, Docker, NATS.
        </>
      ),
      color: '#041C2E',
      paddingTop: '92px',
    },
  ],
};

export const blocksRedirectingToResourcesProps = {
  sectionProps: {
    title: 'Our publications',
    subtitle: (
      <>
        CodiLime is where the network enthusiasts are. And as excited as we are about providing innovative solutions, we
        also love sharing our experiences. Our blog is the place to learn more about our expertise in network
        professional services.
      </>
    ),
    classNames: {
      innerWrapper: styles.blocksRedirectingToResourcesInnerWrapper,
    },
  },
  blocks: [
    {
      text: 'Network automation – why does it matter?',
      link: '/blog/network-automation/',
    },
    {
      text: 'Integration of Tungsten Fabric SDN, OVS, and SR-IOV compute nodes with an ML2 OpenStack mechanism',
      link: '/case-study/integration-of-tungsten-fabric-sdn-ovs-and-sr-iov-compute-nodes-with-ml2-openstack-mechanism/',
    },
    {
      text: 'Creating an NFV telco cloud based on OpenStack and Tungsten Fabric',
      link: '/case-study/creating-nfv-telco-cloud-based-on-openstack-and-tungsten-fabric/',
    },
    {
      text: 'Building a web application for network monitoring in heterogeneous environments',
      link: '/case-study/building-web-application-for-network-monitoring-in-heterogeneous-environments/',
    },
  ],
};

export const coloredBlocksWithRedirectsProps = {
  title: 'Explore our other services',
  subtitle:
    'Network professional services is our main area of expertise, but we do much more at CodiLime. We can help you in many other business cases - from building MVPs, to designing UX and big data management.',
  blocks: [
    {
      text: (
        <>
          <span>Data engineering services</span>
        </>
      ),
      link: paths.SERVICES.DATA_ENGINEERING,
    },
    {
      text: (
        <>
          <span>MVP development</span>
          <span>services</span>
        </>
      ),
      link: paths.SERVICES.MVP,
    },
    {
      text: (
        <>
          <span>UX/UI Design for complex</span>
          <span>network applications</span>
        </>
      ),
      link: paths.SERVICES.UX_UI_DESIGN,
    },
    {
      text: (
        <>
          <span>Low-level programming</span>
        </>
      ),
      link: paths.SERVICES.LOW_LEVEL,
    },
  ],
  classNames: {
    innerWrapper: styles.coloredBlocksInnerWrapper,
  },
};

export const techStackWithCategoriesProps = {
  sectionProps: {
    title: 'Technology stack',
    subtitle: (
      <>
        At CodiLime, we focus on what our customers need:{' '}
        <b>time-to-market, cost optimization, or scalability requirements.</b> To do so, we need a wide range of
        expertise and technologies. We are qualified in Linux-based and SDN networks, private and public cloud
        frameworks, telco and data center networks, and various programming languages to provide software development
        when needed.
      </>
    ),
  },
  classNames: {
    rectangleStyles: styles.rectangleStyles,
    categoryTitle: styles.techStackCategoryTitle,
  },
  blocksForFirstColumn: [
    {
      categoryTitle: 'Backend',
      technologiesData: [go, cPlusPlus, python, pytest],
    },
    {
      categoryTitle: 'Automation',
      technologiesData: [ansible, terraform, puppet, saltStack],
    },
    {
      categoryTitle: 'CI/CD',
      technologiesData: [circleCI, travisCI, bamboo, jenkins, spinnaker, zuul, gitLab],
    },
    {
      categoryTitle: 'IaaC',
      technologiesData: [vault, vagrant, helm, juju, groovy, git],
    },
    {
      categoryTitle: 'Monitoring',
      technologiesData: [fluentd, prometheus, grafana, elkStack],
    },
    {
      categoryTitle: 'Public cloud',
      technologiesData: [googleCloud, aws, azure, ibmCloud],
    },
    {
      categoryTitle: 'Private cloud',
      technologiesData: [redHat, openStack],
    },
    {
      categoryTitle: 'Cloud-native services',
      technologiesData: [openShift, kubernetes, docker, envoy, istio],
    },
    {
      categoryTitle: 'Virtualization',
      technologiesData: [kvm, xen],
    },
    {
      categoryTitle: 'Storage / database',
      technologiesData: [etcd, mariaDB, postgreSQL, mongoDB],
    },
    {
      categoryTitle: 'Open-source networking',
      technologiesData: [p4, sonic, openVSwitch, tungstenFabric],
    },
    {
      categoryTitle: 'Vendor solutions',
      technologiesData: [
        cisco,
        paloAltoNetworks,
        juniperNetworks,
        dell,
        aristaNetworks,
        huawei,
        f5,
        checkPoint,
        nokiaNetworks,
      ],
    },
  ],
};

export const opacityScrolledSliderProps = {
  title: 'What can network professional services bring to your project?',
  subtitle: (
    <>
      When working with us, you can be sure that all the load of building a networking solution, optimizing an existing
      network, or automating a network solution <b>is taken off your shoulders</b>. We make sure that our solutions are
      exactly what your business needs. In what other ways do network services contribute to business success?
    </>
  ),
  textBlocks: [
    <>
      Better-informed decisions with the support of top notch <span>network experts.</span>
    </>,
    <>
      A wide range of skills covering <span>the full scope of the project.</span>
    </>,
    <>
      Easy to work with and <span>fully-automated solutions</span> that reduce effort on the client side.
    </>,
    <>
      <span>Effective knowledge transfer</span> and wide-ranging support for the client&apos;s engineers.
    </>,
    <>
      Automated tests, CI/CD practices, and validation automation for <span>streamlined delivery.</span>
    </>,
  ],
  classNames: {
    innerWrapper: styles.opacityScrolledSliderInnerWrapper,
  },
};

export const bannerWithImageProps = {
  title: 'Kubernetes application networking: maximize its potential',
  extraTitleOnDesktop: ' with Ingress, service mesh, Gateway API',
  bannerCategory: 'Kubernetes',
};
