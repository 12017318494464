// extracted by mini-css-extract-plugin
export var blocksInRowsWithOneActiveInnerWrapper = "payload-module--blocks-in-rows-with-one-active-inner-wrapper--105e3";
export var blocksInRowsWithOneActiveSection = "payload-module--blocks-in-rows-with-one-active-section--bbbd1";
export var blocksRedirectingToResourcesInnerWrapper = "payload-module--blocks-redirecting-to-resources-inner-wrapper--c4273";
export var cardContainer = "payload-module--card-container--b2033";
export var coloredBlocksInnerWrapper = "payload-module--colored-blocks-inner-wrapper--472b4";
export var contactButtonImageSection = "payload-module--contact-button-image-section--03ebc";
export var contactButtonImageSectionImageContainer = "payload-module--contact-button-image-section-image-container--bc9af";
export var contactButtonImageSectionInnerWrapper = "payload-module--contact-button-image-section-inner-wrapper--89432";
export var content = "payload-module--content--6d4b5";
export var customSubtitle = "payload-module--custom-subtitle--f42ef";
export var imageContainer = "payload-module--image-container--b1a8e";
export var innerWrapperTestimonials = "payload-module--inner-wrapper-testimonials--c1fec";
export var opacityScrolledSliderInnerWrapper = "payload-module--opacity-scrolled-slider-inner-wrapper--83930";
export var paragraphWithGap = "payload-module--paragraph-with-gap--a2bb9";
export var rectangleStyles = "payload-module--rectangle-styles--2fc01";
export var singleBlockContainer = "payload-module--single-block-container--66394";
export var techStackCategoryTitle = "payload-module--tech-stack-category-title--f382a";
export var titleBorderWidth = "payload-module--title-border-width--864fb";
export var titlesContainer = "payload-module--titles-container--22394";
export var underHeaderInnerWrapper = "payload-module--under-header-inner-wrapper--5a333";